export const routes = {
   token: '/api/token',
   system: '/api/system',
   forgottenPassword: '/api/forgotten-password',
   user: '/api/user',
   userRights: '/api/user-rights',
   docTypes: '/api/doc-types',
   role: '/api/role',
   resource: '/api/resource',
   eventLog: '/api/event-log',
   translation: '/api/translation',
   parameter: '/api/parameter',
   f2a: '/api/f2a',
};
