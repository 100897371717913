import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppComponent } from '../app.component';
import { paths } from '../config/paths';

@Injectable({
   providedIn: 'root',
})
export class CanActivateChildService implements CanActivateChild {
   // eslint-disable-next-line no-useless-constructor
   constructor(private router: Router) {}

   canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const roles: string[] | undefined = route.data.roles;
      if (AppComponent.token) {
         if (!roles?.length) {
            return true;
         }
         if (!roles?.every((r) => AppComponent.token?.roles.includes(r))) {
            this.router.navigate([paths.home]);
            return false;
         }
         return true;
      }
      this.router.navigate([paths.login], { queryParams: { returnUrl: state.url } });
      return false;
   }
}
