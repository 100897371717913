import { Component, OnInit } from '@angular/core';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { AppComponent } from '../app.component';
import { paths } from '../config/paths';

@Component({
   selector: 'app-navbar',
   templateUrl: './navbar.component.html',
   styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
   app = AppComponent;

   items: MenuItem[] = [];

   paths = paths;

   ngOnInit(): void {
      this.init();
   }

   init(): void {
      this.items = [
         {
            label: 'Chaos Auth',
            routerLink: [paths.home],
            escape: false,
         },
         {
            label: 'Master files',
            icon: PrimeIcons.FILE,
            items: [
               { label: 'Systems', routerLink: [paths.system.home], icon: PrimeIcons.SITEMAP, visible: this.app.isRole('system-admin') },
               { label: 'Users', routerLink: [paths.user.home], icon: PrimeIcons.USER, visible: this.app.isRole('user-admin') },
               { label: 'Roles', routerLink: [paths.role.home], icon: PrimeIcons.USERS, visible: this.app.isRole('role-admin') },
               { label: 'Resources', routerLink: [paths.resource.home], icon: PrimeIcons.KEY, visible: this.app.isRole('resource-admin') },
            ],
         },
         {
            label: 'Quick menu',
            icon: PrimeIcons.TABLE,
            items: [
               { label: 'User-roles', routerLink: [paths.quick.userRoles], icon: PrimeIcons.USERS, visible: this.app.isRole('user-admin') && this.app.isResource('chaos-auth.user-add-roles') },
               {
                  label: 'Resource-roles',
                  routerLink: [paths.quick.roleResources],
                  icon: PrimeIcons.KEY,
                  visible: this.app.isRole('role-admin') && this.app.isResource('chaos-auth.role-add-resources'),
               },
            ],
         },
         {
            label: 'Settings',
            icon: PrimeIcons.COG,
            items: [
               { label: 'Parameters', routerLink: [paths.parameters], icon: PrimeIcons.CHECK_CIRCLE, visible: this.app.isRole('parameter-admin') },
               { label: 'Translations', routerLink: [paths.translation], icon: PrimeIcons.GLOBE, visible: this.app.isRole('translation-admin') },
            ],
         },
         { label: 'Event log', routerLink: [paths.eventLog], icon: PrimeIcons.BOOK, visible: this.app.isRole('event-log-admin') },
      ];
   }
}
