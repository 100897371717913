<p-dialog header="{{dialogContent?.resourceName}}" [(visible)]="dialogVisible" [style]="{width: '50vw'}" [baseZIndex]="10000">
   <span>{{ dialogContent?.method }} </span>
   <span>{{ dialogContent?.url }} </span>
   <span [ngStyle]="getStatusColor(dialogContent?.status)">{{ dialogContent?.status }} </span>
   <span [ngStyle]="getProcessTimeColor(dialogContent?.processTime)">{{ dialogContent?.processTime }} </span>
   <pre>{{dialogContent?.body | json }}</pre>
   <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="dialogVisible=false" label="Ok" styleClass="p-button-text"></p-button>
   </ng-template>
</p-dialog>

<div class="p-shadow-23" style="margin: 1rem;">
   <p-table [columns]="columns" [value]="eventLogs" [paginator]="true" [rows]="25" [lazy]="true" (onLazyLoad)="loadCustomers($event)" [totalRecords]="totalRecords" styleClass="p-datatable-sm">
      <ng-template pTemplate="caption">
         <div class="p-d-flex p-ai-center p-jc-between">
            EventLog
         </div>
      </ng-template>
      <ng-template pTemplate="header">
         <tr>
            <th [ngStyle]="{ 'text-align' : 'left', 'width':'10%' }">{{ 'Request Time' | translate }}</th>
            <th [ngStyle]="{ 'width':'5%', 'text-align' : 'center' }">{{ 'Method' | translate }}</th>
            <th [ngStyle]="{ 'width':'20%' }">{{ 'Url' | translate }}</th>
            <th [ngStyle]="{ 'width':'10%' }">{{'Resource code' | translate }}</th>
            <th [ngStyle]="{ 'text-align' : 'center', 'width':'5%' }">{{ 'Status' | translate }}</th>
            <th [ngStyle]="{ 'text-align' : 'left', 'width':'5%' }">{{ 'Process time' | translate }}</th>
            <th [ngStyle]="{ 'text-align' : 'left', 'width':'5%' }">{{ 'IP address' | translate }}</th>
            <th [ngStyle]="{ 'text-align' : 'center', 'width':'5%' }">{{ 'Request parameters' | translate }}</th>

         </tr>
      </ng-template>
      <ng-template pTemplate="body" let-eventLogs>
         <tr>
            <td [ngStyle]="{ 'text-align' : 'left' }">{{eventLogs.createdAt}}</td>
            <td [ngStyle]="{ 'text-align' : 'center' }" >{{eventLogs.method}}</td>
            <td>{{eventLogs.url}}</td>
            <td pTooltip="{{eventLogs.resourceName}}" tooltipPosition="top">{{eventLogs.resourceCode}}</td>
            <td [ngStyle]="getStatusColor(eventLogs.status,{ 'text-align' : 'center'  })" >{{eventLogs.status}}</td>
            <td [ngStyle]="getProcessTimeColor(eventLogs.processTime)">{{eventLogs.processTime}}</td>
            <td>{{eventLogs.ipAddress}}</td>
            <td [ngStyle]="{ 'text-align' : 'center' }">
               <button 
                  *ngIf="eventLogs.body!=''"
                  pButton 
                  class="p-button-raised p-button-rounded" 
                  style="font-size: 10rem;" 
                  icon="pi pi-info-circle" 
                  (click)="showRequestParams(eventLogs)"
                  ></button></td>

         </tr>
      </ng-template>
   </p-table>
</div>