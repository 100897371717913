<p-confirmDialog icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div id="form" class="p-shadow-23" style="margin: 1rem; padding: 1rem;">
   <form [formGroup]="parameterForm" (submit)="onSubmit()">
      <div class="p-fluid p-grid p-formgrid">
         <div class="p-field p-col-6">
            <label for="code">{{'Code'|translate}}</label>
            <input id="code" type="text" formControlName="code" pInputText autofocus>
         </div>
         <div class="p-field p-col-6">
            <label for="value">{{'Value'|translate}}</label>
            <input id="value" type="text" formControlName="value" pInputText>
         </div>
         <div class="p-col-2">
            <button pButton type="submit" [label]="'Submit'|translate" class="p-button-success"
               [disabled]="parameterForm.invalid" *ngIf="!isUpdate"></button>
            <button pButton type="submit" [label]="'Modify'|translate" class="p-button-warning"
               [disabled]="parameterForm.invalid" *ngIf="isUpdate"></button>
         </div>
         <div class="p-col-2">
            <button pButton type="reset" [label]="'Reset'|translate" class="p-button-light"
               (click)='onReset()'></button>
         </div>
      </div>
   </form>
</div>
<div class="p-shadow-23" style="margin: 1rem;">
   <lib-table [header]="'Parameter list'|translate" [columns]="columns|translate:'header'" [rows]="parameter"
      [rowButtons]="rowButtons|translate:'header'" (onRowButton)="onButton($event)">
   </lib-table>
</div>
