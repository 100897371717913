import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { paths } from './config/paths';
import { EventLogComponent } from './event-log/event-log.component';
import { F2aComponent } from './f2a/f2a.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ParameterComponent } from './parameter/parameter.component';
import { CanActivateChildService } from './services/can-activate-child.service';
import { CanActivateLoginService } from './services/can-activate-login.service';
import { CanActivateService } from './services/can-activate.service';
import { TranslationComponent } from './translation/translation.component';

const routes: Routes = [
   { path: paths.login, component: LoginComponent, canActivate: [CanActivateLoginService] },
   { path: paths.forgottenPassword, component: ForgottenPasswordComponent, canActivate: [CanActivateLoginService] },
   { path: paths.home, component: HomeComponent, canActivate: [CanActivateService] },
   { path: paths.logout, component: LogoutComponent, canActivate: [CanActivateService] },
   {
      path: paths.system.home,
      loadChildren: () => import('./system/system.module').then((m) => m.SystemModule),
      canActivateChild: [CanActivateChildService],
      data: { roles: ['system-admin'] },
   },
   {
      path: paths.user.home,
      loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
      canActivateChild: [CanActivateChildService],
      data: { roles: ['user-admin'] },
   },
   {
      path: paths.role.home,
      loadChildren: () => import('./role/role.module').then((m) => m.RoleModule),
      canActivateChild: [CanActivateChildService],
      data: { roles: ['role-admin'] },
   },
   {
      path: paths.resource.home,
      loadChildren: () => import('./resource/resource.module').then((m) => m.ResourceModule),
      canActivateChild: [CanActivateChildService],
      data: { roles: ['resource-admin'] },
   },
   {
      path: paths.quick.home,
      loadChildren: () => import('./quick/quick.module').then((m) => m.QuickModule),
      canActivateChild: [CanActivateChildService],
   },
   {path:paths.f2a, component: F2aComponent, canActivate:[CanActivateService], data:{ roles: ['f2a'] }},
   { path: paths.parameters, component: ParameterComponent, canActivate: [CanActivateService], data: { roles: ['parameter-admin'] } },
   { path: paths.translation, component: TranslationComponent, canActivate: [CanActivateService], data: { roles: ['translation-admin'] } },
   { path: paths.eventLog, component: EventLogComponent, canActivate: [CanActivateService], data: { roles: ['event-log-admin'] } },
   { path: '**', redirectTo: paths.home },
];

@NgModule({
   imports: [RouterModule.forRoot(routes)],
   exports: [RouterModule],
})
export class AppRoutingModule {}
