<div style="text-align: center;">
   <h1>{{(('Welcome back'|translate)||'')+', ' + user?.username}}</h1>
</div>
<div class="p-fluid p-formgrid p-grid">
   <div class="p-field p-col-12 p-sm-6 p-lg-6 p-shadow-23" style="text-align: center;">

      <h2>{{'Your roles:'|translate}}</h2>
      <ul class="comma-list">
         <li *ngFor="let role of user?.roles">
            {{role}}
         </li>
      </ul>
   </div>
   <div class="p-field p-col-12 p-sm-6 p-lg-6 p-shadow-23" style="text-align: center; padding-bottom: 1rem;">
      <h2>{{'Your resources:'|translate}}</h2>
      <ul class="comma-list">
         <li *ngFor="let resource of user?.resources">
            {{resource}}
         </li>
      </ul>
   </div>
</div>
