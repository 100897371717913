import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TableModule } from '@chaos-solutions/table';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PasswordModule } from 'primeng/password';
import { MainModule } from 'src/main/main.module';
import { TableModule as  PrimeNgTableModule} from 'primeng/table';
import {TooltipModule} from 'primeng/tooltip';
import {DialogModule} from 'primeng/dialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EventLogComponent } from './event-log/event-log.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { HomeComponent } from './home/home.component';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { SpinnerInterceptor } from './interceptors/spinner.interceptor';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ParameterComponent } from './parameter/parameter.component';
import { TranslationComponent } from './translation/translation.component';
import { F2aComponent } from './f2a/f2a.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';

@NgModule({
   declarations: [
      AppComponent,
      LoginComponent,
      ForgottenPasswordComponent,
      HomeComponent,
      NavbarComponent,
      LogoutComponent,
      EventLogComponent,
      TranslationComponent,
      ParameterComponent,
      F2aComponent,
   ],
   imports: [
      MainModule,
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      FormsModule,
      HttpClientModule,
      CheckboxModule,
      MenubarModule,
      PasswordModule,
      TableModule,
      DropdownModule,
      CalendarModule,
      SharedModule,
      DynamicDialogModule,
      ConfirmPopupModule,
      ConfirmDialogModule,
      OverlayPanelModule,
      InputNumberModule,
      PrimeNgTableModule,
      TooltipModule,
      DialogModule,
   ],
   providers: [
      { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true }, 
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
       ConfirmationService],
   bootstrap: [AppComponent],
})
export class AppModule {}
