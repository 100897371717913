<div *ngIf="!!app.spinner">
   <img class="spinner-img" src="../assets/logo.gif">
   <div class="dimmer"></div>
</div>
<app-navbar *ngIf="app.token && !app.f2aRequested"></app-navbar>
<router-outlet></router-outlet>
<div style="height: 20px;"></div>
<footer class="p-menubar" style="padding: 0; height: 20px;">
   <div class="p-d-flex p-jc-between">
      <div style="margin-left: 3px;">
         {{version}}{{app.token?' - '+app.token.username:''}}
      </div>
      <div style="margin-right: 3px;">© {{fromYear === year ? year : (fromYear+'-'+year)}} Copyright:
         <a href="https://chaos-solutions.hu/"> Chaos Solutions Bt.</a>
      </div>
   </div>
</footer>
<app-reset-dialog [dialogHeader]="app.dialogHeader" [(dialogMessage)]="app.dialogMessage" [dialogValue]="undefined">
</app-reset-dialog>
