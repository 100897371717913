import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
   selector: 'app-reset-dialog',
   templateUrl: './reset-dialog.component.html',
   styleUrls: ['./reset-dialog.component.css'],
})
export class ResetDialogComponent {
   @Input() dialogMessage = '';

   @Input() dialogValue: string | undefined = '';

   @Input() dialogHeader = '';

   @Output() dialogValueChange = new EventEmitter<string>();

   @Output() dialogMessageChange = new EventEmitter<string>();
}
