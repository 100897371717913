import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IColumn, IRowButton } from '@chaos-solutions/table';
import { HttpService, isResultValid } from '@szegedsw/http.service';
import { ConfirmationService } from 'primeng/api';
import { routes } from '../config/routes';
import { TranslatePipe } from '../translation/translate.pipe';

@Component({
   selector: 'app-parameter',
   templateUrl: './parameter.component.html',
   styleUrls: ['./parameter.component.css'],
   providers: [ConfirmationService],
})
export class ParameterComponent implements OnInit {
   service: HttpService;

   from = 0;

   parameter: any[] = [];

   columns: IColumn[] = [
      { field: 'code', header: 'Code' },
      { field: 'value', header: 'Value' },
   ];

   rowButtons: IRowButton[] = [
      { header: 'Edit', button: 'pi-pencil' },
      { header: 'Delete', button: 'pi-trash', class: 'p-button-danger' },
   ];

   isUpdate = false;

   parameterForm = new FormGroup({
      code: new FormControl({ value: '', disabled: false }, [Validators.required]),
      value: new FormControl({ value: '', disabled: false }, [Validators.required]),
   });

   constructor(private http: HttpClient, public route: ActivatedRoute, private confirmationService: ConfirmationService) {
      this.service = new HttpService(this.http, routes.parameter);
   }

   ngOnInit(): void {
      this.getParameter();
   }

   getParameter(): void {
      this.service.get<any>({}, (res) => {
         if (isResultValid(res)) {
            this.parameter = res.body || [];
         }
      });
   }

   onButton(event: { row: any; rowButton: string }): void {
      if (event.rowButton === 'pi-trash') {
         this.confirm(event);
      } else if (event.rowButton === 'pi-pencil') {
         this.onEdit(event);
      }
   }

   confirm(event: { row: any; rowButton: string }): void {
      this.confirmationService.confirm(
         TranslatePipe.translate(
            {
               header: 'Delete confirmation',
               message: 'Are you sure that you delete this record?',
               acceptLabel: 'Yes',
               acceptButtonStyleClass: 'p-button-danger',
               rejectLabel: 'No',
               accept: () => {
                  this.onDelete(event);
               },
            },
            ...['header', 'message', 'acceptLabel', 'rejectLabel'],
         ),
      );
   }

   onEdit(event: { row: any; rowButton: string }): void {
      Object.keys(event.row).forEach((k) => this.parameterForm.get(k)?.setValue(event.row[k]));
      this.parameterForm.get('code')?.disable();
      this.isUpdate = true;
   }

   onDelete(event: { row: any; rowButton: string }): void {
      this.service.delete('', { code: event.row.code }, (res) => {
         if (isResultValid(res)) {
            this.getParameter();
            this.onReset();
         }
      });
   }

   onSubmit(): void {
      this.parameterForm.enable();
      if (this.isUpdate) {
         this.service.patch<any>('', this.parameterForm.value, (res) => {
            if (isResultValid(res)) {
               this.getParameter();
               this.onReset();
            }
         });
      } else {
         this.service.post<any>(this.parameterForm.value, (res) => {
            if (isResultValid(res)) {
               this.getParameter();
               this.onReset();
            }
         });
      }
   }

   onReset(): void {
      const code: any = document.querySelector('input[id=code]');
      code.focus();
      this.parameterForm?.reset();
      this.isUpdate = false;
   }
}
