<p-dialog [header]="dialogHeader|translate" [visible]="!!dialogValue || (dialogValue === undefined && !!dialogMessage)"
   [modal]="true" [breakpoints]="[{'960px': '75vw'}]" [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="false"
   [resizable]="false">
   <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
         <label for="token">{{dialogMessage|translate}}</label>
         <input *ngIf="dialogValue" id="token" type="text" [value]="dialogValue" readonly pInputText>
      </div>
   </div>
   <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="dialogValueChange.emit('');dialogMessageChange.emit('')"
         [label]="'OK'|translate" styleClass="p-button-text">
      </p-button>
   </ng-template>
</p-dialog>
