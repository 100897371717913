import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IColumn, IRowButton } from '@chaos-solutions/table';
import { HttpService, isResultValid } from '@szegedsw/http.service';
import { ConfirmationService } from 'primeng/api';
import { routes } from '../config/routes';
import { TranslatePipe } from './translate.pipe';

@Component({
   selector: 'app-translation',
   templateUrl: './translation.component.html',
   styleUrls: ['./translation.component.css'],
   providers: [ConfirmationService],
})
export class TranslationComponent implements OnInit {
   service: HttpService;

   from = 0;

   translation: any[] = [];

   columns: IColumn[] = [
      { field: 'en', header: 'English' },
      { field: 'hu', header: 'Hungarian' },
   ];

   rowButtons: IRowButton[] = [
      { header: 'Edit', button: 'pi-pencil' },
      { header: 'Delete', button: 'pi-trash', class: 'p-button-danger' },
   ];

   isUpdate = false;

   translationForm = new FormGroup({
      en: new FormControl({ value: '', disabled: false }, [Validators.required]),
      hu: new FormControl({ value: '', disabled: false }, [Validators.required]),
   });

   constructor(private http: HttpClient, public route: ActivatedRoute, private confirmationService: ConfirmationService) {
      this.service = new HttpService(this.http, routes.translation);
   }

   ngOnInit(): void {
      this.getTranslation();
   }

   getTranslation(): void {
      this.service.get<any>({}, (res) => {
         if (isResultValid(res)) {
            this.translation = res.body || [];
         }
      });
   }

   onButton(event: { row: any; rowButton: string }): void {
      if (event.rowButton === 'pi-trash') {
         this.confirm(event);
      } else if (event.rowButton === 'pi-pencil') {
         this.onEdit(event);
      }
   }

   confirm(event: { row: any; rowButton: string }): void {
      this.confirmationService.confirm(
         TranslatePipe.translate(
            {
               header: 'Delete confirmation',
               message: 'Are you sure that you delete this record?',
               acceptLabel: 'Yes',
               acceptButtonStyleClass: 'p-button-danger',
               rejectLabel: 'No',
               accept: () => {
                  this.onDelete(event);
               },
            },
            ...['header', 'message', 'acceptLabel', 'rejectLabel'],
         ),
      );
   }

   onEdit(event: { row: any; rowButton: string }): void {
      Object.keys(event.row).forEach((k) => this.translationForm.get(k)?.setValue(event.row[k]));
      this.translationForm.get('en')?.disable();
      this.isUpdate = true;
   }

   onDelete(event: { row: any; rowButton: string }): void {
      this.service.delete('', { en: event.row.en }, (res) => {
         if (isResultValid(res)) {
            this.getTranslation();
            this.onReset();
         }
      });
   }

   onSubmit(): void {
      this.translationForm.enable();
      if (this.isUpdate) {
         this.service.patch<any>('', this.translationForm.value, (res) => {
            if (isResultValid(res)) {
               TranslatePipe.add(this.translationForm.get('en')?.value, this.translationForm.get(TranslatePipe.locale)?.value);
               this.getTranslation();
               this.onReset();
            }
         });
      } else {
         this.service.post<any>(this.translationForm.value, (res) => {
            if (isResultValid(res)) {
               TranslatePipe.add(this.translationForm.get('en')?.value, this.translationForm.get(TranslatePipe.locale)?.value);
               this.getTranslation();
               this.onReset();
            }
         });
      }
   }

   onReset(): void {
      const en: any = document.querySelector('input[id=en]');
      en.focus();
      this.translationForm?.reset();
      this.isUpdate = false;
   }
}
