import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TableModule } from '@chaos-solutions/table';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { TranslatePipe } from 'src/app/translation/translate.pipe';
import { ResetDialogComponent } from './reset-dialog/reset-dialog.component';

@NgModule({
   declarations: [TranslatePipe, ResetDialogComponent],
   imports: [CommonModule, CardModule, TableModule, DialogModule, MessagesModule, ButtonModule, ReactiveFormsModule, InputTextModule],
   exports: [CommonModule, TranslatePipe, ResetDialogComponent, CardModule, TableModule, DialogModule, MessagesModule, ButtonModule, ReactiveFormsModule, InputTextModule],
})
export class MainModule {}
