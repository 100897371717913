import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IColumn } from '@chaos-solutions/table';
import { HttpService, isResultValid } from '@szegedsw/http.service';
import { format } from 'date-fns';
import { LazyLoadEvent } from 'primeng/api';
import { AppComponent } from '../app.component';
import { routes } from '../config/routes';


type EventLogResponse = {
   resource: {
      code: string;
      name: string;
   };
   body: any;
   status: number;
   createdAt: Date;
   url: string;
   method: string;
   ipAddress: string;
   processTime: number;
};

type RemappedEventLogType = {
   resourceCode: string;
   resourceName: string;
   body: string;
   status: number;
   createdAt: string;
   url: string;
   method: string;
   ipAddress: string;
   processTime: string;
};

@Component({
   selector: 'app-event-log',
   templateUrl: './event-log.component.html',
   styleUrls: ['./event-log.component.css'],
})
export class EventLogComponent implements OnInit {
   service: HttpService;

   countLogsService: HttpService;

   dialogVisible = false;

   dialogContent: RemappedEventLogType | null = null;

   eventLogs: any[] = [];

   from = 0;

   loading = false;

   columns: IColumn[] = [
      // { field: '_id', header: 'System ID' },
      { field: 'resourceCode', header: 'Code' },
      { field: 'body', header: 'Body' },
      { field: 'status', header: 'Status' },
      { field: 'createdAt', header: 'Requested At' },
      { field: 'processTime', header: 'Process time' },
      { field: 'ipAddress', header: 'IP adress' },
   ];

   // selectedSystem: any[] = [];

   systemId: string | undefined;

   totalRecords = 0;

   constructor(private http: HttpClient, public route: ActivatedRoute) {
      this.service = new HttpService(this.http, routes.eventLog);
      this.countLogsService = new HttpService(this.http, `${routes.eventLog}/total`);
      this.systemId = route.snapshot.params.system_id;
   }

   ngOnInit(): void {
      const systemId = AppComponent.token?.systemId;
      this.countLogsService.get<number>({ systemId }, (res) => {
         if (res.body) {
            this.totalRecords = res.body;
         }
      });
   }

   showRequestParams(data: any): void {
      this.dialogContent = { ...data };
      if (this.dialogContent) this.dialogContent.body = JSON.parse(this.dialogContent?.body) || '';
      this.dialogVisible = true;
   }

   // eslint-disable-next-line @typescript-eslint/ban-types
   getProcessTimeColor(str: string | undefined, inlineFormatting?: object): object {
      let color = 'white';
      if (str && str.length > 3) {
         const num = parseInt(str.substr(0, str.length - 3), 10);
         if (num >= 600) {
            color = 'red';
         } else if (num >= 400) {
            color = 'orange';
         }
      }
      return { color, ...inlineFormatting };
   }

   // eslint-disable-next-line @typescript-eslint/ban-types
   getStatusColor(num: number | undefined, inlineFormatting?: object): object {
      let color = 'white';
      if (num) {
         if (num >= 500) {
            color = 'red';
         }
         if (num >= 400) {
            color = 'orange';
         }
         if (num >= 300) {
            color = 'gray';
         } else if (num >= 200) {
            color = 'green';
         }
      }
      return { color, ...inlineFormatting };
   }

   loadCustomers(event: LazyLoadEvent) {
      this.loading = true;
      const systemId = AppComponent.token?.systemId;
      let params = {};
      if (typeof systemId !== 'undefined' && typeof event.first !== 'undefined' && typeof event.rows !== 'undefined') {
         params = { systemId, skip: +event.first, take: +event.rows };
      }

      setTimeout(() => {
         this.service.get<EventLogResponse[]>({ systemId, skip: event.first || 0, take: event.rows || 0 }, (res) => {
            if (isResultValid(res)) {
               if (res.body) {
                  this.eventLogs = res.body.map<RemappedEventLogType>((item) => {
                     const newItem = {
                        resourceCode: item.resource?.code,
                        resourceName: item.resource?.name,
                        body: JSON.stringify(item.body || '', null, 2),
                        status: item.status,
                        createdAt: format(new Date(item.createdAt), 'yyyy/MM/dd HH:mm:ss'),
                        url: item.url,
                        method: item.method,
                        ipAddress: item.ipAddress,
                        processTime: `${item.processTime || 0} ms`,
                     };
                     return newItem;
                  });
               } else {
                  this.eventLogs = [];
               }
            }
         });
         this.loading = false;
      });
   }
}
