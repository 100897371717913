import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService, isResultValid } from '@szegedsw/http.service';
import { MessageService } from 'primeng/api';
import { paths } from '../config/paths';
import { routes } from '../config/routes';

@Component({
   selector: 'app-forgotten-password',
   templateUrl: './forgotten-password.component.html',
   styleUrls: ['./forgotten-password.component.css'],
   providers: [MessageService],
})
export class ForgottenPasswordComponent {
   service: HttpService;

   paths = paths;

   constructor(private http: HttpClient, private fb: FormBuilder, private messageService: MessageService) {
      this.service = new HttpService(this.http, routes.forgottenPassword);
   }

   form: FormGroup = this.fb.group({
      user_id: this.fb.control('', [Validators.required]),
      email: this.fb.control('', [Validators.required, Validators.email]),
   });

   resetPassword(): void {
      this.service.patch('', this.form.value, (res) => {
         this.messageService.clear();
         if (isResultValid(res)) {
            this.messageService.add({ severity: 'success', summary: 'Email sent to mailbox' });
         } else {
            this.messageService.add({ severity: 'error', summary: 'User/email not found' });
         }
         this.form.reset();
      });
   }
}
