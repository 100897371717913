import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService, isResultValid } from '@szegedsw/http.service';
import { AppComponent } from '../app.component';
import { paths } from '../config/paths';
import { routes } from '../config/routes';

@Component({
  selector: 'app-f2a',
  templateUrl: './f2a.component.html',
  styleUrls: ['./f2a.component.css'],
})
export class F2aComponent {

  qrcode: string;

  window = window;

  service: HttpService;

  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router) { 
    this.qrcode = AppComponent.qr || "";
    this.service = new HttpService(this.http, routes.f2a);
  }


  
  form = this.fb.group({
    totp: this.fb.control('', [Validators.required]),
  });

  paths =paths;


  sendF2a() :void {
     this.service.post<{ token: string, f2a?:{ qr:string } }>({ ...this.form.value}, (res) => {
         if (isResultValid(res)) {
            if (AppComponent.redirect.getUrlAfterRedirects() === `/${AppComponent.redirect.getDefaultUrl()}`) {
               AppComponent.redirect.setUrlAfterRedirects(null);
            }

            AppComponent.token = JSON.parse(atob(res.body?.token.split('.')[1] || ''));
            AppComponent.qr = res.body?.f2a?.qr;
            if(AppComponent.token?.roles && AppComponent.token.roles.includes('f2a')){
               AppComponent.redirect.setDefaultUrl(paths.f2a);
               AppComponent.f2aRequested = true;
               this.router.navigate([paths.f2a]);
            } else {
               AppComponent.f2aRequested=false;
               AppComponent.redirect.setDefaultUrl(paths.home);
               AppComponent.redirect.toReturnUrl();
            }
         }
      });
   }
}
