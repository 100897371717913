import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
   intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      const jwtCredentials: string | null = localStorage.getItem('jwt');
      let authorizedReq = request;

      if (jwtCredentials) {
         authorizedReq = request.clone({
            headers: request.headers.set('jwt', `${jwtCredentials}`),
         });
      }

      return next.handle(authorizedReq).pipe(
         map((event: any) => {
            if (event instanceof HttpResponse && event.headers.get('jwt')) {
               localStorage.setItem('jwt', event.headers.get('jwt') || '');
            }
            return event;
         }),
      );
   }
}
