import { Component } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
   selector: 'app-home',
   templateUrl: './home.component.html',
   styleUrls: ['./home.component.css'],
})
export class HomeComponent {
   user = AppComponent.token;
}
