import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppComponent } from '../app.component';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
   intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      AppComponent.spinner += 1;
      return next.handle(request).pipe(
         tap(
            (event: HttpEvent<any>) => {
               if (event instanceof HttpResponse) {
                  setTimeout(() => (AppComponent.spinner -= 1), 200);
               }
            },
            (err: any) => {
               AppComponent.spinner--;
            },
         ),
      );
   }
}
