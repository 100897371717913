import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService, isResultValid } from '@szegedsw/http.service';
import { paths } from '../config/paths';
import { routes } from '../config/routes';
import { AppComponent } from '../app.component';

@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
   styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
   window = window;

   constructor(private fb: FormBuilder, private http: HttpClient, private router: Router) {
      this.service = new HttpService(this.http, routes.token);
   }

   service: HttpService;

   paths = paths;

   form = this.fb.group({
      username: this.fb.control('', [Validators.required]),
      password: this.fb.control('', [Validators.required, Validators.minLength(8)]),
   });

   ngOnInit(): void {
      // itt le kell kérdezni a tokent --> get --> ha 200 vagyis valid --> router navgate --> returnurl
   }

   login(): void {
      AppComponent.f2aRequested=false;
      this.service.post<{ token: string, f2a?:{ qr:string } }>({ ...this.form.value, url: window.location.origin }, (res) => {
         if (isResultValid(res)) {
            if (AppComponent.redirect.getUrlAfterRedirects() === `/${AppComponent.redirect.getDefaultUrl()}`) {
               AppComponent.redirect.setUrlAfterRedirects(null);
            }

            AppComponent.token = JSON.parse(atob(res.body?.token.split('.')[1] || ''));
            AppComponent.qr = res.body?.f2a?.qr;
            if(AppComponent.token?.roles && AppComponent.token.roles.includes('f2a')){
               AppComponent.redirect.setDefaultUrl(paths.f2a);
               AppComponent.f2aRequested = true;
               this.router.navigate([paths.f2a]);
            } else {
               AppComponent.redirect.setDefaultUrl(paths.home);
               AppComponent.redirect.toReturnUrl();
            }
         }
      });
   }
}
