<form class="center" (submit)="resetPassword()" [formGroup]="form">
   <div class="p-fluid p-formgrid p-grid">
      <h2 class="p-text-center p-col-12" style="margin-bottom: 0;">Chaos Auth</h2>
      <h4 class="p-text-center p-col-12">{{'Forgotten password'|translate}}</h4>
      <div class="p-col-12">
         <p-messages></p-messages>
      </div>
      <div class="p-field p-col-12">
         <label for="user_id">{{'Username'|translate}}</label>
         <input id="user_id" type="text" formControlName="user_id" pInputText>
      </div>
      <div class="p-field p-col-12">
         <label for="email">{{'Email'|translate}}</label>
         <input id="email" type="email" formControlName="email" pInputText>
      </div>
      <div class="p-col-12">
         <button pButton type="submit" [label]="'Reset password'|translate" class="p-button-success"
            [disabled]="form.invalid"></button>
         <a [routerLink]="['/'+paths.login]">{{'Back'|translate}}</a>
      </div>
   </div>
</form>
