import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService, isResultValid } from '@szegedsw/http.service';
import { AppComponent } from '../app.component';
import { paths } from '../config/paths';
import { routes } from '../config/routes';

@Component({
   selector: 'app-logout',
   templateUrl: './logout.component.html',
   styleUrls: ['./logout.component.css'],
})
export class LogoutComponent implements OnInit {
   service: HttpService;

   constructor(private http: HttpClient, private router: Router) {
      this.service = new HttpService(this.http, routes.token);
   }

   ngOnInit(): void {
      this.logout();
   }

   async logout(): Promise<void> {
      await this.service.delete('', {}, (res) => {
         if (isResultValid(res)) {
            AppComponent.token = undefined;
            localStorage.removeItem('jwt');
            localStorage.clear();
            this.router.navigate([paths.home]);
         }
      });
   }
}
