export const paths = {
   home: '',
   login: 'login',
   forgottenPassword: 'forgotten-password',
   system: {
      home: 'system',
      accessTokens: 'system/access-tokens',
      urls: 'system/urls',
   },
   logout: 'logout',
   user: {
      home: 'user',
      roles: 'user/roles',
   },
   role: {
      home: 'role',
      resources: 'role/resources',
   },
   eventLog: 'event-log',
   resource: {
      home: 'resource',
   },
   parameters: 'parameters',
   translation: 'translation',
   quick: {
      home: 'quick',
      userRoles: 'quick/user-roles',
      roleResources: 'quick/role-resources',
   },
   f2a:'f2a',
};
