import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { HttpService } from '@szegedsw/http.service';
import { routes } from '../config/routes';

@Pipe({
   name: 'translate',
   pure: false,
})
export class TranslatePipe implements PipeTransform {
   public static locale: string;

   private static translations: Record<any, any> = {};

   private static loaded: boolean;

   private static service: HttpService;

   constructor(private http: HttpClient) {
      TranslatePipe.service = new HttpService(this.http, routes.translation).subRoute('/locale');
   }

   transform<T>(value: T, ...args: string[]): T {
      return TranslatePipe.translate(value, ...args);
   }

   public static translate<T>(value: T, ...args: string[]): any {
      if (!Object.keys(this.translations).length && !this.loaded) {
         this.loaded = true;
         this.getLocale();
         return value;
      }
      if (typeof value === 'string') {
         return this.translations[value] || value;
      }
      if (Array.isArray(value)) {
         return value.map((v) => {
            if (!v) {
               return v;
            }
            args.forEach((a) => {
               if (Array.isArray(v[a])) {
                  v[a] = this.translate(v[a], ...args);
               } else {
                  v[a] = this.translate(v[a]);
               }
            });
            return v;
         });
      }
      if (value && Object.keys(value).length) {
         args.forEach((k) => ((value as any)[k] = this.translate((value as any)[k], ...args)));
      }
      return value;
   }

   public static add(key: string, value: string): void {
      this.translations[key] = value;
   }

   private static getLocale() {
      this.service.get<Record<string, string>[]>({}, (res) => {
         const keys = Object.keys(res.body?.[0] || {});
         this.translations = res.body?.reduce((p, c) => ({ ...p, [c[keys[0]]]: c[keys[1]] }), {}) || {};
         this.locale = keys[1];
      });
   }
}
