<form class="center" (submit)="sendF2a()" [formGroup]="form">
    <div class="p-fluid p-formgrid p-grid">
        <h2 class="p-text-center p-col-12 my-title" style="margin-bottom: 0;">{{'2 Factor Authentication' | translate}}</h2><br><br>
        <div style ="display: flex; justify-content: center;" class="p-col-12" *ngIf="qrcode"><img src="{{qrcode}}" style="border: 2px black solid;"></div>
        <div class="p-field p-col-12">
            <label for="totp">{{'One time password'|translate}}</label>
            <input id="totp" type="text" formControlName="totp" pInputText>
        </div>
        <div class="p-col-12">
            <button pButton type="submit" [label]="'Send'|translate" class="p-button-success"
                [disabled]="form.invalid"></button>
            <a [routerLink]="['/'+paths.logout]">{{'Kijelentkezés'|translate}}</a>
        </div>
    </div>
</form>

<!--  -->