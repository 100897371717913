<form class="center" (submit)="login()" [formGroup]="form">
   <div class="p-fluid p-formgrid p-grid">
      <h2 class="p-text-center p-col-12" style="margin-bottom: 0;">Chaos Auth</h2>
      <h4 class="p-text-center p-col-12">{{window.location.origin.split('/').reverse()[0]}}</h4>
      <div class="p-field p-col-12">
         <label for="username">{{'Domain'|translate}}/{{'Username'|translate}}</label>
         <input id="username" type="text" formControlName="username" pInputText autocomplete="username">
      </div>
      <div class="p-field p-col-12">
         <label for="password">{{'Password'|translate}}</label>
         <input id="password" type="password" formControlName="password" pPassword [feedback]="false"
            autocomplete="current-password">
      </div>
      <div class="p-col-12">
         <button pButton type="submit" [label]="'Login'|translate" class="p-button-success"
            [disabled]="form.invalid"></button>
         <a [routerLink]="['/'+paths.forgottenPassword]">{{'Forgotten password'|translate}}</a>
      </div>
   </div>
</form>
