/* eslint-disable no-useless-constructor */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppComponent } from '../app.component';
import { paths } from '../config/paths';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
   constructor(private router: Router) {}

   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(
         catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
               if (AppComponent.token) {
                  AppComponent.token = undefined;
                  AppComponent.dialogHeader = 'Session expired';
                  AppComponent.dialogMessage = 'Your session has expired. Please log in!';
                  AppComponent.redirect.setDefaultUrl(paths.login);
                  AppComponent.redirect.setUrlAfterRedirects(null);
                  AppComponent.redirect.toDefaultUrl();
               }
            } else {
               AppComponent.dialogHeader = 'Error happened';
               AppComponent.dialogMessage = error.error.message;
            }
            return throwError(error);
         }),
      );
   }
}
