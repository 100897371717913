/* eslint-disable @typescript-eslint/no-var-requires */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HttpService, isResultValid, Redirect } from '@szegedsw/http.service';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { paths } from './config/paths';
import { routes } from './config/routes';

declare let require: any;

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
   static spinner = 0;

   version: number;

   static redirect: Redirect;

   static token: { username: string; systemId: string; userId: string; roles: string[]; resources: string[]; iat: number; iss: string; aud: string; exp: number } | undefined;

   static qr: string | undefined;

   static f2aRequested: boolean;

   static dialogHeader: string;

   static dialogMessage: string;

   app = AppComponent;

   service: HttpService;

   routerSubscription: Subscription;

   year = new Date().getFullYear();

   fromYear = 2021;

   constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient, private config: PrimeNGConfig, private changeDetector: ChangeDetectorRef) {
      this.service = new HttpService(this.http, routes.token);

      AppComponent.redirect = new Redirect(this.router, this.route);
      AppComponent.redirect.setDefaultUrl(paths.login);
      AppComponent.redirect.setReloadUrl(paths.login);

      this.routerSubscription = this.router.events.subscribe((event) => {
         if (event instanceof NavigationEnd) {
            AppComponent.redirect.setUrlAfterRedirects(event.urlAfterRedirects);
         }
      });

      this.version = require('../../package.json').version;
   }

   ngOnInit(): void {
      this.service.get<{ token: string, f2a?:{qr:string} }>({}, (res) => {
         if (isResultValid(res)) {
            AppComponent.token = JSON.parse(atob(res.body?.token.split('.')[1] || ''));

            AppComponent.qr = res.body?.f2a?.qr;
            if(AppComponent.token?.roles && AppComponent.token.roles.includes('f2a')){
               AppComponent.redirect.setDefaultUrl(paths.f2a);
               AppComponent.f2aRequested = true;
               this.router.navigate([paths.f2a]);
               return;
            } 
            
            if (AppComponent.redirect.getUrlAfterRedirects() === `/${AppComponent.redirect.getDefaultUrl()}`) {
               AppComponent.redirect.setUrlAfterRedirects(null);
            }
            AppComponent.redirect.setDefaultUrl(paths.home);
         } else if (res instanceof HttpErrorResponse) {
            AppComponent.token = undefined;
            AppComponent.redirect.setDefaultUrl(paths.login);
         }
         AppComponent.redirect.toReturnUrl();
      });
   }

   ngAfterViewChecked(): void {
      this.changeDetector.detectChanges();
   }

   ngOnDestroy(): void {
      this.routerSubscription.unsubscribe();
   }

   static isRole(...roles: string[]): boolean {
      return !!roles?.every((r) => AppComponent.token?.roles.includes(r));
   }

   static isResource(...resources: string[]): boolean {
      return !!resources?.every((r) => AppComponent.token?.resources.includes(r));
   }
}
